import React, { useEffect, useContext, useState } from "react";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import _ from "lodash";
import { useOrder } from "../../queries";
import { CartContext } from "../../context";
import { useNavigate, useRemoveSearchParams } from "../../hooks";
import { StaticImage } from "gatsby-plugin-image";
import { useTrans } from "../../hooks";

const Checkout = ({ pageContext, location }) => {
  const { seoData } = pageContext;
  const transMsg = useTrans();
  const navigate = useNavigate();

  const { data, error, onExecuteQuery, startPolling, stopPolling } = useOrder();
  const [dispalyError, setDispalyError] = useState(false);

  const [counter, setCounter] = useState(10);

  useEffect(() => {
    if (_.isEmpty(data) || _.isEmpty(data?.storefrontOrder)) return;
    const interval = setInterval(() => setCounter((c) => c - 1), 1000);
    if (counter < 1)
      navigate(
        `/orders/${data.storefrontOrder?.displayOrderId}?token=${data.storefrontOrder?.token}`,
      );
    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const { clearCart } = useContext(CartContext);
  const searchParams = useRemoveSearchParams(location);
  useEffect(() => {
    if (_.isEmpty(searchParams)) return;
    const { token } = searchParams;
    if (_.isEmpty(token)) return;
    clearCart();
    onExecuteQuery({ token });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (!_.isFunction(startPolling)) return;
    startPolling(1000);
  }, [startPolling]);

  useEffect(() => {
    // check if data is empty and if storefrontOrder is empty or null
    // if there is no data or storefrontOrder, return
    if (_.isEmpty(data) || _.isEmpty(data?.storefrontOrder)) return;

    // stop polling since we got the data
    stopPolling();

    // TODO: ask why we need to poll this data and not request it once
    // my theory is that we need to poll it because we need to wait for the
    // order to be created in the backend

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (_.isEmpty(error)) return;
    const timer = setTimeout(() => {
      setDispalyError(true);
      console.log(JSON.stringify(error));
      stopPolling();
      throw new Error(`Error Creating order at ${process.env.GATSBY_STRAPI_RESTAURANT_ID}`);
    }, 10000);

    return () => clearTimeout(timer);
  }, [stopPolling, error]);

  return (
    <Layout seo={seoData}>
      <section className="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
        <Container>
          <Row className="text-center">
            <Col sm={12}>
              {dispalyError && (
                <>
                  <StaticImage src="../../images/thanks.png" alt="thanks" />
                  <div className="mt-2 mb-2">{transMsg("orderSubmittedMessage")}</div>
                  <div className="mt-2 mb-2">
                    {transMsg("checkEmailConfirmationMessage")}
                  </div>
                </>
              )}
            </Col>
            <Col sm={12}>
              {(_.isEmpty(data) || _.isEmpty(data?.storefrontOrder)) && !dispalyError && (
                <>
                  <h3 className="mt-2 mb-2">{transMsg("creatingYourOrderMessage")}</h3>
                  <Spinner animation="border" variant="info" />
                </>
              )}
              {!_.isEmpty(data) && !_.isEmpty(data?.storefrontOrder) && (
                <>
                  <Col sm={12}>
                    <StaticImage src="../../images/thanks.png" alt="thanks" />
                    <h1 className="mt-2 mb-2"> {transMsg("congratulations")}</h1>
                    <div className="mb-5">{transMsg("successfullyPlacedOrderMessage")}</div>
                    <h4> {transMsg("emailSent")} </h4>
                    <h6 className="text-info">
                      {transMsg("orderNotificationsSpamMessage")}
                    </h6>
                  </Col>
                  <br />
                  <Link
                    to={`/orders/${data.storefrontOrder?.displayOrderId}?token=${data.storefrontOrder?.token}`}
                  >
                    {transMsg("viewOrderDetails")}
                  </Link>

                  <p className="text-muted font-weight-light py-2">
                    {transMsg("autoDirectMessage", { counter })}
                  </p>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Checkout;
